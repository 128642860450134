<script>
export default {
  props: {
    current: {
      type: Number,
      default: 0
    },
    parameters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    parsedParams() {
      const currentParam = this.parameters.find(({ id }) => this.current === id);
      return this.parameters.map((param, i) => {
        return ({
        ...param,
        complete: param.ordem < currentParam.ordem,
        divider: i < this.parameters.length - 1
      })
      });
    }
  }
};
</script>

<template>
  <div v-if="!parameters.length" class="no-data">
    Não existem dados para mostrar.
  </div>
  <v-stepper v-else v-model="current" horizontal>
    <v-stepper-header>
      <template v-for="(param, i) in parsedParams">
        <v-stepper-step
          :class="{ complete: param.complete }"
          :key="`step-${param.id}`"
          :step="param.ordem || i"
        >
          {{ param.nome_fluxo }}
        </v-stepper-step>
        <v-divider v-if="param.divider" :key="`divider-${param.id}`" />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  text-align: center;
}

.v-stepper {
  box-shadow: none;
}

.v-stepper__header {
  align-items: flex-start;
  height: auto;

  .v-divider {
    align-self: unset;
    margin: 0.6rem -1.5rem 0;
  }
}

.v-stepper__step {
  flex-direction: column;
  padding: 0 1.5rem;
  width: 8rem;

  &::v-deep {
    .v-stepper__step__step {
      margin: 0;
      background-color: #ffc440 !important;
      border-color: #ffc440 !important;
    }

    .v-stepper__label {
      color: #ffc440;
      text-align: center;
    }
  }

  & + .v-divider.v-divider {
    border-radius: 1rem;
    border-top: 0.25rem solid #ffc440;
  }

  &.complete {
    &::v-deep {
      .v-stepper__step__step {
        background-color: #6bb4ba !important;
        border-color: #6bb4ba !important;
      }

      .v-stepper__label {
        color: #6bb4ba;
      }
    }

    & + .v-divider.v-divider {
      border-top-color: #6bb4ba;
    }
  }
}
</style>
