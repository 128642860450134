<script>
export default {
  props: {
    parameters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    parsedApprovers() {
      return this.parameters.map(({ revisores }) => Array.isArray(revisores) ? revisores.map(({ nome }) => nome).join(', ') : '');
    }
  }
};
</script>

<template>
  <div class="approvers-container">
    <div v-for="(app, index) of parsedApprovers" class="approvers-label" :key="index">
      {{ app }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.approvers-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .approvers-label {
    color: #C5C7D0;
    font-size: 0.75rem;
    font-weight: 300;
    padding: 0 1.5rem;
    text-align: center;
    width: 8rem;
  }
}
</style>
