<script>
import moment from "moment";
import Approvers from "./approvers-timeline.vue";
import CommentsList from './comments-list.vue';
import Timeline from "./horizontal-timeline.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Approvers,
    CommentsList,
    Modal: () => import("@/components/g-modal.vue"),
    Timeline,
  },
  props: {
    parameters: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      approversModal: {
        data: { emails: [] },
        opened: false
      },
      commentsModal: {
        opened: false
      },
      emails: []
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    comments() {
      const comentarios = this.value?.workflows?.reduce((acc, { workflow: { nome_fluxo, ordem }, revisores }) => {
        const comments = revisores?.reduce((accComments, { comentario, id, criado_em, modificado_em, responsavel: { email, nome } }) => {
          if (!comentario) {
            return accComments;
          }

          return [
            ...accComments,
            {
              comentario,
              criado_em,
              id,
              modificado_em,
              nome_fluxo,
              ordem,
              responsavel: nome ? `${nome}${email ? ` (${email})` : ''}` : email
            }
          ];
        }, []) ?? [];

        return [...acc, ...comments];
      }, []) ?? [];

      comentarios.sort(({ modificado_em: modificadoA, ordem: ordemA }, { modificado_em: modificadoB, ordem: ordemB }) => ordemB - ordemA + modificadoB.localeCompare(modificadoA));

      return comentarios;
    },
    parsedApprovers() {
      return Array.isArray(this.value.aprovadores)
        ? this.value.aprovadores
        : [];
    },
    parsedDate() {
      const dateA = moment(this.value.data_inicio, "YYYY-MM-DD");
      const dateB = moment(this.value.data_fim, "YYYY-MM-DD");
      const parsed = [dateA, dateB].map(date =>
        date.isValid() ? date.format("DD/MM/YYYY") : "Data indefinida."
      );
      return parsed.join(" — ");
    },
    slaColor() {
      const status = this.value.sla_status_workflow;

      if (status === 'Concluído') {
        return "#135153";
      }

      if (status === 'Atrasado') {
        return "#EC4C47";
      }

      return null;
    },
    showReviewersBtn() {
      const { definir_revisores } = this.value?.workflow ?? {};
      return !!definir_revisores;
    },
    statusColor() {
      return this.value.sla_status_workflow !== 'Concluído' ? "#FFC440" : "#6BB4BA";
    }
  },
  created() {
    this.getEmails();
  },
  methods: {
    async doSaveApprovers(close) {
      try {
        const isValid = this.$refs.approversForm.validate();
  
        if (isValid) {
          const resource = this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-business-plans/${this.value.id}/${this.value.business_plan_workflow_id}`);
          const revisores = this.approversModal.data.emails;
          await resource.save({ revisores });
  
          if (close) {
            close();
          }
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async getEmails() {
      try {
        this.emails = [];
        const { get } = this.apiResource(
          `v1/ativacaoped/${this.clientId}/responsaveis`
        );
        const response = await get();
        this.emails = response.data.map(({ email, id, nome }) => ({
          id,
          value: `${nome || "Sem Nome"} (${email || "sem e-mail"})`
        }));
      } catch (error) {
        this.notify(error);
        this.emails = [];
      }
    },
    goToBP() {
      this.$router.push({
        name: "ativacaoped-projetos-form-edicao",
        params: { id: this.value.id }
      });
    },
    openModal() {
      this.approversModal.data = { emails: [] };
      this.approversModal.opened = true;
      this.$refs.approversForm?.reset();
    },
    required(value) {
      return (Array.isArray(value) && value.length > 0) || "Obrigatório.";
    }
  }
};
</script>

<template>
  <v-card class="px-2" elevation="4">
    <div class="content-container">
      <div class="group">
        <div class="item">
          <h1>{{ value.nome }}</h1>
          <span>{{ parsedDate }}</span>
        </div>
        <div class="item">
          <h4>SLA</h4>
          <span :style="{ color: slaColor }">{{ value.sla_status_workflow }}</span>
        </div>
      </div>
      <div class="group">
        <div class="item">
          <Timeline :current="value.workflow.id" :parameters="parameters" />
        </div>
        <div class="item">
          <h4>Aprovadores</h4>
          <Approvers :parameters="parameters" />
        </div>
      </div>
    </div>

    <div class="action-container">
      <div class="status" :style="{ color: statusColor }">
        <v-icon large>mdi-circle-small</v-icon>
        <span>{{ value.workflow.status_label }}</span>
      </div>
      <div class="buttons">
        <v-badge v-if="comments.length" :color="tableColor" :content="comments.length" offset-y="16" offset-x="24" :value="comments.length">
          <v-btn :color="tableColor" text @click="commentsModal.opened = true">
            Ver comentários
          </v-btn>
        </v-badge>

        <v-btn v-if="showReviewersBtn" :color="tableColor" text @click="openModal">
          Definir aprovadores
        </v-btn>

        <v-btn :color="tableColor" depressed @click="goToBP">
          Visualizar Business Plan
        </v-btn>
      </div>
    </div>

    <Modal v-model="approversModal.opened" title="Definir aprovadores">
      <p>Selecione um ou mais aprovadores para este business plan.</p>

      <v-form ref="approversForm" lazy-validation>
        <v-autocomplete
          v-model="approversModal.data.emails"
          dense
          label="E-mail"
          multiple
          outlined
          :items="emails"
          item-value="id"
          item-text="value"
          :rules="[required]"
        />
      </v-form>

      <template #buttons="{ close }">
        <v-btn
          class="px-5 ml-3"
          color="primary"
          dark
          depressed
          @click="doSaveApprovers(close)"
        >
          Salvar
        </v-btn>
        <v-btn class="px-5 ml-3" color="primary" dark text @click="close()">
          Cancelar
        </v-btn>
        <v-spacer />
      </template>
    </Modal>

    <Modal v-model="commentsModal.opened" hide-buttons title="Comentários por etapa" width="600px">
      <CommentsList :comments="comments" />
    </Modal>
  </v-card>
</template>

<style lang="scss" scoped>
@import "@/scss/_vuetify.scss";

.action-container,
.content-container {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
}

.action-container {
  align-items: center;
  justify-content: space-between;

  .status {
    span {
      color: inherit;
      font-size: 0.75rem;
      font-weight: 300;
    }

    .v-icon {
      color: inherit;
      font-size: 0.75rem;
    }
  }

  .v-btn {
    padding: 0.625rem 1rem;
    margin: 0 0.25rem;
  }
}

.content-container {
  border-bottom: thin solid #00000040;
  display: flex;
  flex-direction: row;

  @include md {
    flex-direction: column;
  }

  h1,
  h4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  h4 {
    font-size: 0.75rem;
  }

  span {
    color: #c5c7d0;
    font-size: 0.75rem;
  }

  .group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item {
    height: 100%;
  }

  .item + .item {
    margin-top: 0.5rem;
  }
}
</style>
