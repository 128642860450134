<script>
import BusinessCard from "./components/business-card.vue";
import { toNumber } from "lodash";
import { mapGetters } from 'vuex';

export default {
  components: { BusinessCard },
  data() {
    return {
      businessPlans: [],
      parameters: []
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    resource() {
      return this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-business-plans`);
    }
  },
  created() {
    this.getParameters();
    this.doLoad();
  },
  methods: {
    async doLoad() {
      try {
        const response = await this.resource.get();
        this.businessPlans = response?.data.sort(({ nome: a }, { nome: b }) => a - b);
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }

        this.businessPlans = [];
      }
    },
    async doSave(data) {
      try {
        const body = { ...data, sla: toNumber(data.sla || 0) };
        await this.resource.save(body, data.id);
        this.$notify({
          title: "Sucesso!",
          text: `Parametro ${data.id ? "alterado" : "criado"} com sucesso.`,
          type: "success",
          group: "geral",
          duration: 5000
        });

        this.doLoad();
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async getParameters() {
      try {
        const resource = this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-parametrizacao`);
        const response = await resource.get();
        this.parameters = response.data;
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }

        this.parameters = [];
      }
    }
  },
  watch: {
    clientId() {
      this.getParameters();
      this.doLoad();
    }
  }
};
</script>

<template>
  <v-card :color="tableColor" elevation="0">
    <v-card-text class="d-flex">
      <div
        aria-label="refreshButton"
        class="table-v-action-button mr-2"
        @click="doLoad"
      >
        <v-icon>mdi-refresh</v-icon>
        Atualizar
      </div>
    </v-card-text>

    <div class="cards-container">
      <div class="no-data" v-if="!businessPlans.length">
        Não há business plans preenchidos para exibir.
      </div>

      <BusinessCard
        v-for="plan in businessPlans"
        class="business-card"
        :key="plan.id"
        :parameters="parameters"
        :value="plan"
      />
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.table-v-action-button {
  user-select: none;
}

.no-data {
  width: 100%;
  text-align: center;
}

.cards-container {
  background-color: #fff;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding: 1rem 2.75rem;

  .business-card {
    margin-bottom: 1rem;
  }
}
</style>
